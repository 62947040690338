import axios from 'axios';

//提交名单信息
export async function PostMember(memberinfo){
    var res = await axios.post('Member/v1/Member', memberinfo)
    .then(function (response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //修改名单信息
  export async function PutMemberInfoData(memberinfo){
    var res = await axios.put('Member/v1/MemberInfo', memberinfo)
    .then(function (response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //修改名单的其他信息
  export async function PutMemberElseInfoData(memberelseinfo){
    var res = await axios.put('Member/v1/MemberElseInfo', memberelseinfo)
    .then(function (response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //根据ID获取监护人信息（名单信息）
  export async function GetMemberByID(id){
    var res = await axios.get('Member/v1/MemberByID', { params: { memberid:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }


//查询名单信息
export async function GetMemberQuery(queryInfo){
  var res = await axios.get('Member/v1/MemberQuery', { params: queryInfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}